import React, { Component } from 'react';
import './AppFooter.css';

class AppFooter extends Component {
  render() {
    return (
      <footer>
          <a className="copyright" href="https://michelingroup.sharepoint.com/sites/MappingFactory?market=en-US">Mapping Factory</a>
      </footer>
    );
  }
}

export default AppFooter;
